import { Navigate } from 'react-router';
import { parseISO, subDays } from 'date-fns';
import { TradeDatePicker, TraderModals } from '@/App/Trader/components';
import { PredeliverRate } from '@/App/Operation/Predeliver/PredeliverRate';
import { oppositeWay } from '@/models/way';
import { PredeliverRemaining } from './PredeliverRemaining';
import { OperationAlert } from '../Shared/OperationAlert';
import { routes } from '@/App/routes';
import { TradeLoader } from '@/App/Trader/TradeLoader';
import { OperationAmountAndCurrency } from '../Shared/OperationAmountAndCurrency';
import { OperationActionButtons } from '../Shared/OperationActionButtons';
import { selectPredeliver, selectPredeliverCurrencyPair, selectPredeliverSide } from '@/store/state/predeliver/predeliver.selectors';
import { selectSpotDates } from '@/store/state/trades/trades.selectors';
import { PredeliverHeader } from './PredeliverHeader';
import { MainLayout } from '@/App/Layout/MainLayout';
import { useAppSelector } from '@/store/store';

export function Predeliver() {
  const currencyPair = useAppSelector(selectPredeliverCurrencyPair);
  const spotDate = useAppSelector(selectSpotDates)[currencyPair!];
  const defaultMaxDate = spotDate ? parseISO(spotDate) : undefined;
  const side = useAppSelector(selectPredeliverSide);
  const {trade} = useAppSelector(selectPredeliver);
  const maxDate= trade?.instrumentName === 'FxoAmericanForward' ? subDays(trade?.maturitydate,1) : defaultMaxDate

  

  return side ? (
    <MainLayout hasCustomizedHeader>
      <PredeliverHeader />

      <TradeLoader>
        <div>
          <OperationAmountAndCurrency type="predeliver" way={side} />
          <OperationAmountAndCurrency type="predeliver" way={oppositeWay(side)} readonly />

          <div className="col-md-4 pt-5 pe-0 ps-0">
            <TradeDatePicker className="date-picker-predeliver" maxDate={maxDate} />
          </div>

          <PredeliverRate />
          <PredeliverRemaining />
          <OperationAlert />
          <OperationActionButtons />
          <TraderModals />
        </div>
      </TradeLoader>
    </MainLayout>
  ) : (
    <Navigate replace to={routes.HOME} />
  );
}
