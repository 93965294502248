/// <reference types="vite-plugin-svgr/client" />

import aedFlag from 'flag-icon-css/flags/4x3/ae.svg?react';
import afnFlag from 'flag-icon-css/flags/4x3/af.svg?react';
import amdFlag from 'flag-icon-css/flags/4x3/am.svg?react';
import aoaFlag from 'flag-icon-css/flags/4x3/ao.svg?react';
import arsFlag from 'flag-icon-css/flags/4x3/ar.svg?react';
import audFlag from 'flag-icon-css/flags/4x3/au.svg?react';
import azmFlag from 'flag-icon-css/flags/4x3/az.svg?react';
import bamFlag from 'flag-icon-css/flags/4x3/ba.svg?react';
import bgnFlag from 'flag-icon-css/flags/4x3/bg.svg?react';
import bhdFlag from 'flag-icon-css/flags/4x3/bh.svg?react';
import bobFlag from 'flag-icon-css/flags/4x3/bo.svg?react';
import brlFlag from 'flag-icon-css/flags/4x3/br.svg?react';
import broFlag from 'flag-icon-css/flags/4x3/br.svg?react';
import bsdFlag from 'flag-icon-css/flags/4x3/bs.svg?react';
import bwpFlag from 'flag-icon-css/flags/4x3/bw.svg?react';
import bzdFlag from 'flag-icon-css/flags/4x3/bz.svg?react';
import cadFlag from 'flag-icon-css/flags/4x3/ca.svg?react';
import chfFlag from 'flag-icon-css/flags/4x3/ch.svg?react';
import clpFlag from 'flag-icon-css/flags/4x3/cl.svg?react';
import cnyFlag from 'flag-icon-css/flags/4x3/cn.svg?react';
import copFlag from 'flag-icon-css/flags/4x3/co.svg?react';
import crcFlag from 'flag-icon-css/flags/4x3/cr.svg?react';
import cveFlag from 'flag-icon-css/flags/4x3/cv.svg?react';
import cypFlag from 'flag-icon-css/flags/4x3/cy.svg?react';
import czkFlag from 'flag-icon-css/flags/4x3/cz.svg?react';
import djfFlag from 'flag-icon-css/flags/4x3/dj.svg?react';
import dkkFlag from 'flag-icon-css/flags/4x3/dk.svg?react';
import dopFlag from 'flag-icon-css/flags/4x3/do.svg?react';
import dzdFlag from 'flag-icon-css/flags/4x3/dz.svg?react';
import eekFlag from 'flag-icon-css/flags/4x3/ee.svg?react';
import egpFlag from 'flag-icon-css/flags/4x3/eg.svg?react';
import etbFlag from 'flag-icon-css/flags/4x3/et.svg?react';
import eurFlag from 'flag-icon-css/flags/4x3/eu.svg?react';
import gbpFlag from 'flag-icon-css/flags/4x3/gb.svg?react';
import ghsFlag from 'flag-icon-css/flags/4x3/gh.svg?react';
import gtqFlag from 'flag-icon-css/flags/4x3/gt.svg?react';
import hkdFlag from 'flag-icon-css/flags/4x3/hk.svg?react';
import hnlFlag from 'flag-icon-css/flags/4x3/hn.svg?react';
import hrkFlag from 'flag-icon-css/flags/4x3/hr.svg?react';
import htgFlag from 'flag-icon-css/flags/4x3/ht.svg?react';
import hufFlag from 'flag-icon-css/flags/4x3/hu.svg?react';
import idrFlag from 'flag-icon-css/flags/4x3/id.svg?react';
import ilsFlag from 'flag-icon-css/flags/4x3/il.svg?react';
import inrFlag from 'flag-icon-css/flags/4x3/in.svg?react';
import iskFlag from 'flag-icon-css/flags/4x3/is.svg?react';
import jpyFlag from 'flag-icon-css/flags/4x3/jp.svg?react';
import kesFlag from 'flag-icon-css/flags/4x3/ke.svg?react';
import krwFlag from 'flag-icon-css/flags/4x3/kr.svg?react';
import kwdFlag from 'flag-icon-css/flags/4x3/kw.svg?react';
import kztFlag from 'flag-icon-css/flags/4x3/kz.svg?react';
import lbpFlag from 'flag-icon-css/flags/4x3/lb.svg?react';
import lkrFlag from 'flag-icon-css/flags/4x3/lk.svg?react';
import ltlFlag from 'flag-icon-css/flags/4x3/lt.svg?react';
import lvlFlag from 'flag-icon-css/flags/4x3/lv.svg?react';
import madFlag from 'flag-icon-css/flags/4x3/ma.svg?react';
import mgaFlag from 'flag-icon-css/flags/4x3/mg.svg?react';
import mmkFlag from 'flag-icon-css/flags/4x3/mm.svg?react';
import mxnFlag from 'flag-icon-css/flags/4x3/mx.svg?react';
import murFlag from 'flag-icon-css/flags/4x3/mu.svg?react';
import myrFlag from 'flag-icon-css/flags/4x3/my.svg?react';
import mznFlag from 'flag-icon-css/flags/4x3/mz.svg?react';
import ngnFlag from 'flag-icon-css/flags/4x3/ng.svg?react';
import nokFlag from 'flag-icon-css/flags/4x3/no.svg?react';
import nprFlag from 'flag-icon-css/flags/4x3/np.svg?react';
import nzdFlag from 'flag-icon-css/flags/4x3/nz.svg?react';
import omrFlag from 'flag-icon-css/flags/4x3/om.svg?react';
import penFlag from 'flag-icon-css/flags/4x3/pe.svg?react';
import phpFlag from 'flag-icon-css/flags/4x3/ph.svg?react';
import pkrFlag from 'flag-icon-css/flags/4x3/pk.svg?react';
import plnFlag from 'flag-icon-css/flags/4x3/pl.svg?react';
import jodFlag from 'flag-icon-css/flags/4x3/ps.svg?react';
import pygFlag from 'flag-icon-css/flags/4x3/py.svg?react';
import qarFlag from 'flag-icon-css/flags/4x3/qa.svg?react';
import ronFlag from 'flag-icon-css/flags/4x3/ro.svg?react';
import rsdFlag from 'flag-icon-css/flags/4x3/rs.svg?react';
import rubFlag from 'flag-icon-css/flags/4x3/ru.svg?react';
import sarFlag from 'flag-icon-css/flags/4x3/sa.svg?react';
import sekFlag from 'flag-icon-css/flags/4x3/se.svg?react';
import sgdFlag from 'flag-icon-css/flags/4x3/sg.svg?react';
import sitFlag from 'flag-icon-css/flags/4x3/si.svg?react';
import skkFlag from 'flag-icon-css/flags/4x3/sk.svg?react';
import xafFlag from 'flag-icon-css/flags/4x3/td.svg?react';
import xofFlag from 'flag-icon-css/flags/4x3/tg.svg?react';
import thbFlag from 'flag-icon-css/flags/4x3/th.svg?react';
import tndFlag from 'flag-icon-css/flags/4x3/tn.svg?react';
import tryFlag from 'flag-icon-css/flags/4x3/tr.svg?react';
import trlFlag from 'flag-icon-css/flags/4x3/tr.svg?react';
import twdFlag from 'flag-icon-css/flags/4x3/tw.svg?react';
import uahFlag from 'flag-icon-css/flags/4x3/ua.svg?react';
import ugxFlag from 'flag-icon-css/flags/4x3/ug.svg?react';
import usdFlag from 'flag-icon-css/flags/4x3/us.svg?react';
import uyuFlag from 'flag-icon-css/flags/4x3/uy.svg?react';
import uzbFlag from 'flag-icon-css/flags/4x3/uz.svg?react';
import vebFlag from 'flag-icon-css/flags/4x3/ve.svg?react';
import vndFlag from 'flag-icon-css/flags/4x3/vn.svg?react';
import vuvFlag from 'flag-icon-css/flags/4x3/vu.svg?react';
import yerFlag from 'flag-icon-css/flags/4x3/ye.svg?react';
import zarFlag from 'flag-icon-css/flags/4x3/za.svg?react';
import zmkFlag from 'flag-icon-css/flags/4x3/zm.svg?react';
import { Currency } from '@/models/currency';

export const currencyFlags: Record<Currency, typeof zmkFlag> = {
  USD: usdFlag,
  EUR: eurFlag,
  GBP: gbpFlag,
  CHF: chfFlag,
  JPY: jpyFlag,
  CAD: cadFlag,
  CNH: cnyFlag,
  AUD: audFlag,
  PLN: plnFlag,
  RUB: rubFlag,
  SEK: sekFlag,
  CZK: czkFlag,
  HKD: hkdFlag,
  ZAR: zarFlag,
  NOK: nokFlag,
  SGD: sgdFlag,
  DKK: dkkFlag,
  RON: ronFlag,
  AED: aedFlag,
  THB: thbFlag,
  HUF: hufFlag,
  INR: inrFlag,
  TRY: tryFlag,
  MXN: mxnFlag,
  BRL: brlFlag,
  BRO: broFlag,
  MAD: madFlag,
  NZD: nzdFlag,
  AFN: afnFlag,
  AMD: amdFlag,
  AOA: aoaFlag,
  ARS: arsFlag,
  AZM: azmFlag,
  BAM: bamFlag,
  BGN: bgnFlag,
  BHD: bhdFlag,
  BOB: bobFlag,
  BSD: bsdFlag,
  BWP: bwpFlag,
  BZD: bzdFlag,
  CLP: clpFlag,
  CNY: cnyFlag,
  COP: copFlag,
  CRC: crcFlag,
  CVE: cveFlag,
  CYP: cypFlag,
  DJF: djfFlag,
  DOP: dopFlag,
  DZD: dzdFlag,
  EEK: eekFlag,
  EGP: egpFlag,
  ETB: etbFlag,
  GHS: ghsFlag,
  GTQ: gtqFlag,
  HNL: hnlFlag,
  HRK: hrkFlag,
  HTG: htgFlag,
  IDR: idrFlag,
  ILS: ilsFlag,
  ISK: iskFlag,
  JOD: jodFlag,
  KES: kesFlag,
  KRW: krwFlag,
  KWD: kwdFlag,
  KZT: kztFlag,
  LBP: lbpFlag,
  LKR: lkrFlag,
  LTL: ltlFlag,
  LVL: lvlFlag,
  MGA: mgaFlag,
  MMK: mmkFlag,
  MUR: murFlag,
  MYR: myrFlag,
  MZN: mznFlag,
  NGN: ngnFlag,
  NPR: nprFlag,
  OMR: omrFlag,
  PEN: penFlag,
  PHP: phpFlag,
  PKR: pkrFlag,
  PYG: pygFlag,
  QAR: qarFlag,
  RSD: rsdFlag,
  SAR: sarFlag,
  SIT: sitFlag,
  SKK: skkFlag,
  TND: tndFlag,
  TRL: trlFlag,
  TWD: twdFlag,
  UAH: uahFlag,
  UGX: ugxFlag,
  UYU: uyuFlag,
  UZS: uzbFlag,
  VEB: vebFlag,
  VND: vndFlag,
  VUV: vuvFlag,
  XAF: xafFlag,
  XOF: xofFlag,
  YER: yerFlag,
  ZMK: zmkFlag,
  ZMW: zmkFlag,
};
