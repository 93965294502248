export const allCurrencies = [
  'USD',
  'EUR',
  'GBP',
  'CHF',
  'JPY',
  'CAD',
  'CNH',
  'AUD',
  'PLN',
  'RUB',
  'SEK',
  'CZK',
  'HKD',
  'ZAR',
  'NOK',
  'SGD',
  'DKK',
  'RON',
  'AED',
  'THB',
  'HUF',
  'INR',
  'TRY',
  'MXN',
  'BRL',
  'BRO',
  'MAD',
  'NZD',
  'AFN',
  'AMD',
  'AOA',
  'ARS',
  'AZM',
  'BAM',
  'BGN',
  'BHD',
  'BOB',
  'BSD',
  'BWP',
  'BZD',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CYP',
  'DJF',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ETB',
  'GHS',
  'GTQ',
  'HNL',
  'HRK',
  'HTG',
  'IDR',
  'ILS',
  'ISK',
  'JOD',
  'KES',
  'KRW',
  'KWD',
  'KZT',
  'LBP',
  'LKR',
  'LTL',
  'LVL',
  'MGA',
  'MMK',
  'MUR',
  'MYR',
  'MZN',
  'NGN',
  'NPR',
  'OMR',
  'PEN',
  'PHP',
  'PKR',
  'PYG',
  'QAR',
  'RSD',
  'SAR',
  'SIT',
  'SKK',
  'TND',
  'TRL',
  'TWD',
  'UAH',
  'UYU',
  'UGX',
  'UZS',
  'VEB',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'YER',
  'ZMK',
  'ZMW',
] as const;

export type Currency = (typeof allCurrencies)[number];
