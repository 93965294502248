import { blockExecutionThunk } from '@/store/state/ui/ui.thunks';
import { useAppDispatch } from '@/store/store';
import { SgwtHelpCenter, useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useCallback, useEffect } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-help-center': unknown;
    }
  }
}

const HelpCenterWebAnalyticsCategory = 'Help center';

export const HelpCenterId = 'my-help-center';

export interface HelpCenterApi extends Element {
  open: () => void;
  setDefaultSendTo: (sendTo: string) => void;
}
const helpCenterQuery = `#${HelpCenterId}`;
export const getHelpCenter = () => document.querySelector<HelpCenterApi>(helpCenterQuery)!;

export function HelpCenter() {
  const dispatch = useAppDispatch();
  const { sgwtHelpCenter, sgwtWebAnalytics } = useSgwtWidgets();

  const blockExecution = useCallback(() => {
    dispatch(blockExecutionThunk(true));
  }, [dispatch]);

  const handleOpen = useCallback(() => {
    blockExecution();
    sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, 'Open');
  }, [sgwtWebAnalytics, blockExecution]);

  const handleClose = useCallback(() => {
    blockExecution();
    sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, 'Close');
  }, [sgwtWebAnalytics, blockExecution]);

  const handleSendForm = useCallback(() => {
    blockExecution();
    sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, 'Send Form');
  }, [sgwtWebAnalytics, blockExecution]);

  useEffect(() => {
    sgwtHelpCenter?.addEventListener('sgwt-help-center--open', handleOpen);
    sgwtHelpCenter?.addEventListener('sgwt-help-center--close', handleClose);
    sgwtHelpCenter?.addEventListener('sgwt-help-center--send', handleSendForm);

    return () => {
      sgwtHelpCenter?.removeEventListener('sgwt-help-center--open', handleOpen);
      sgwtHelpCenter?.removeEventListener('sgwt-help-center--close', handleClose);
      sgwtHelpCenter?.removeEventListener('sgwt-help-center--send', handleSendForm);
    };
  }, [sgwtHelpCenter, handleOpen, handleClose, handleSendForm]);

  return (
    <aside>
      <SgwtHelpCenter
        id={HelpCenterId}
        mailSubject={`[SGME-MYFX:${window.sgmeConfiguration.env}] Help request message`}
        language="fr"
        applicationId="sgmarkets-myfx"
        messageOnly
        allowScreenshot
      />
    </aside>
  );
}

HelpCenter.displayName = 'HelpCenter';
