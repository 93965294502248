import { parseISO } from 'date-fns';
import type { AppState } from '@/store/app.state';
import { isSameOrBefore } from '@/utils/dates';

export const isPredeliverAmountInvalid = (state: Pick<AppState, 'predeliver' | 'rfs'>) => (
    state.predeliver.trade !== undefined &&
    (state.predeliver.trade.remainingAmount ?? 0) < state.rfs.buyAmount
  );

export const isPredeliverDateInvalid = (state: Pick<AppState, 'predeliver' | 'rfs'>) => (
    state.predeliver.trade !== undefined &&
    isSameOrBefore(parseISO(state.predeliver.trade.maturitydate), state.rfs.date)
  );

export const selectPredeliver = ({ predeliver }: AppState) => predeliver

export const selectPredeliverTrade = ({ predeliver }: AppState) => predeliver.trade

export const selectPredeliverReference = (state: AppState) => selectPredeliverTrade(state)?.reference

export const selectPredeliverCurrencyPair = (state: AppState) => selectPredeliverTrade(state)?.currencyPair

export const selectPredeliverSide = (state: AppState) => selectPredeliverTrade(state)?.side
