import type { AccountCenterNavigateAsItem } from '@sgwt/sgwt-widgets-react';
import { catchError, map } from 'rxjs/operators';
import { type Feature, featureToggleMappings, type IncomingFeatureToggle } from '@/config/app.config';
import { logger } from '@/logging/logger';
import type { CurrentUser } from '@/models/user';
import { http } from '@/utils/http';

type TypedArray<T extends string, U> = {
  [key in T]: U;
};

const typedEntries = <T extends string, U>(arg: Record<T, U>): [T, U][] => Object.entries(arg) as [T, U][];
const typedFromEntries = <T extends string, U>(arg: [T, U][]): TypedArray<T, U> => Object.fromEntries(arg) as TypedArray<T, U>;

const getCurrentUser = () =>
  http.get<CurrentUser & { featureToggles: Record<IncomingFeatureToggle, boolean> }>('myfx/user/current').pipe(
    map(
      ({
        featureToggles,
        ...user
      }): CurrentUser & {
        featureToggles: Record<Feature, boolean>;
      } => {
        const mappedFeatureToggles = typedFromEntries(typedEntries(featureToggles).map(([key, value]) => [featureToggleMappings[key], value]));
        return { ...user, featureToggles: mappedFeatureToggles };
      },
    ),
  );

const getNavigateAsUser = (email: string, connectionId?: string) =>
  http
    .post(
      'myfx/user/navigateAs',
      {
        navigateAsEmail: email,
        dataStreamId: connectionId,
      },
      {
        'Content-Type': 'application/json',
      },
    )
    .pipe(
      map((res) => res.response as CurrentUser),
      catchError((err) => {
        logger.logError('Error with navigateAs', err);
        throw err;
      }),
    );

const getNavigableAsUsers = () => http.get<AccountCenterNavigateAsItem[]>('myfx/users');

export const UserService = {
  getCurrentUser,
  getNavigateAsUser,
  getNavigableAsUsers,
};
